import {type ActionEvent, Controller} from '@hotwired/stimulus';
import { add } from '@/stores/notifications-store.ts';
import { html, render } from 'htm/preact/index.js';
import { Notifications } from '@/components/shared/Notifications.tsx';
export default class extends Controller {
  initialize() {
    render(html`<${Notifications} />`, this.element as HTMLElement);
  }

  connect() {
    document.addEventListener('autodeal:notifications', (e: Event) => {
      const customEvent = e as CustomEvent;

      customEvent.stopImmediatePropagation();
      this.add(customEvent.detail);
    });
  }

  add({ type, title, message }: { type: string; title: string; message: string }) {
    add(type, title, message);
  }

  remove(event: ActionEvent) {
    const notificationContainer = (event.currentTarget as HTMLElement).closest(
      '.notification-container'
    );

    if (notificationContainer) {
      notificationContainer.remove();
    }
  }
}
