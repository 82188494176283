import { signal } from "@preact/signals";

export interface Labor {
    id?: string;
    description: string;
    price: number;
}

export interface Spare {
    id?: string;
    name: string;
    price: number;
}

export const store = signal({
    labors: {} as { [key: string]: Labor },
    spares: {} as { [key: string]: Spare },
});

export const manageLabors = {
    add: (data: Labor) => {
        store.value = {
            ...store.value,
            labors: { ...store.value.labors, [data.id]: data },
        };
    },
    remove: (data) => {
        const { [data.id]: _, ...rest } = store.value.labors;
        store.value = { ...store.value, labors: rest };
    }
}

export const manageSpares = {
    add: (data: Spare) => {
        store.value = {
            ...store.value,
            spares: { ...store.value.spares, [data.id]: data },
        };
    },
    remove: (data) => {
        const { [data.id]: _, ...rest } = store.value.spares;
        store.value = { ...store.value, spares: rest };
    }
}


