import type { Key } from "preact";
import { type Labor } from "@/stores/confirmation-cart-store.ts";

interface LaborProps extends Omit<Labor, "key"> {
  key?: Key; // React.Key is either string | number
}

export function Labor({ key, description, price }: LaborProps) {
  return (
    <li key={key}>
      <div className="flex my-0.5">
        <span className="flex-1 pr-10 text-sm truncate block overflow-hidden whitespace-nowrap">
          {description}
        </span>
        <span className="text-sm">{price}</span>
      </div>
    </li>
  );
}
