import {Controller} from "@hotwired/stimulus";
import html2canvas from "html2canvas";


export default class ManagerReportsEditIndexController extends Controller {
    static targets = ['preview']

    declare previewTargets: HTMLElement[]
    connect() {
        for (let preview of this.previewTargets) {
            const url = preview.getAttribute('data-url');
            if (url) {
                fetch(`${url}?preview=true`)
                    .then(response => response.text())
                    .then(html => {
                        // Create a hidden container in the current DOM
                        const hiddenContainer = document.createElement('div');
                        hiddenContainer.style.position = 'absolute';
                        hiddenContainer.style.left = '-9999px';
                        hiddenContainer.style.top = '-9999px';

                        // Inject the fetched HTML into the hidden container
                        hiddenContainer.innerHTML = html;
                        document.body.appendChild(hiddenContainer);

                        // Wait for a brief moment to ensure the content is rendered (if there's any async loading)
                        setTimeout(() => {
                            // Use html2canvas to capture the content
                            // @ts-ignore
                            html2canvas(hiddenContainer).then((canvas) => {
                                // Convert the canvas to an image
                                const imgData = canvas.toDataURL('image/png');
                                const img = document.createElement('img');
                                img.src = imgData;
                                img.style.width = '160px'; // Example thumbnail size
                                img.style.height = '90px'; // Example thumbnail size

                                // Append the image to the preview target
                                preview.appendChild(img);

                                // Clean up the hidden container after capturing the preview
                                document.body.removeChild(hiddenContainer);
                            }).catch(err => console.error('Error capturing preview:', err));
                        }, 500);  // Allow some time for content to load if necessary
                    })
                    .catch(err => console.error('Error fetching the URL:', err));
            }
        }
    }
}