import type { QuestionProps } from "@/components/reports/Question.tsx";
import { useState } from "preact/hooks";
import { questionsController } from "@/stores/report-editor-store.ts";

export function Scale({ index, data, type }: QuestionProps) {
    const [scaleData, setScaleData] = useState({
        start: data?.rows?.[0] ?? '1',   // Fallback to '1' if data.rows or rows[0] is undefined
        end: data?.rows?.[1] ?? '5',     // Fallback to '5' if data.rows or rows[1] is undefined
        startTag: data?.columns?.[0] ?? '', // Fallback to empty string if data.columns or columns[0] is undefined
        endTag: data?.columns?.[1] ?? ''   // Fallback to empty string if data.columns or columns[1] is undefined
    });

    const handleSelectChange = (field: keyof typeof scaleData) => (event) => {
        const newValue = event.target.value;
        setScaleData(prevState => {
            const updatedState = {
                ...prevState,
                [field]: newValue
            };
            // Update the controller with the new state immediately after setting it
            questionsController.edit(index, {
                type: type,
                data: {
                    rows: [updatedState.start, updatedState.end],
                    columns: [updatedState.startTag, updatedState.endTag]
                }
            });
            return updatedState;
        });
    };

    const handleInputChange = (field: keyof typeof scaleData) => (event) => {
        const newValue = event.target.value;
        setScaleData(prevState => {
            const updatedState = {
                ...prevState,
                [field]: newValue
            };
            // Update the controller with the new state immediately after setting it
            questionsController.edit(index, {
                type: type,
                data: {
                    rows: [updatedState.start, updatedState.end],
                    columns: [updatedState.startTag, updatedState.endTag]
                }
            });
            return updatedState;
        });
    };

    return (
        <div className="w-full pb-2">
            <div className="flex items-center gap-x-5 mb-5">
                <select
                    className="flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 pr-10 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
                    value={scaleData.start}
                    onChange={handleSelectChange('start')}
                >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                </select>
                <span>a</span>
                <select
                    className="flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 pr-10 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
                    value={scaleData.end}
                    onChange={handleSelectChange('end')}
                >
                    {Array.from({ length: 9 }, (_, i) => (
                        <option key={i + 2} value={i + 2}>{i + 2}</option>
                    ))}
                </select>
            </div>
            <div className='flex flex-col w-full'>
                <div className='flex justify-center items-center'>
                    <span className='mr-2'>{scaleData.start}</span>
                    <input
                        value={scaleData.startTag}
                        onChange={handleInputChange('startTag')}
                        placeholder={`Etiqueta de inicio (opcional)`}
                        className='w-full ml-2 border-0 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300'
                    />
                </div>
                <div className='flex justify-center items-center'>
                    <span className='mr-2'>{scaleData.end}</span>
                    <input
                        value={scaleData.endTag}
                        onChange={handleInputChange('endTag')}
                        placeholder={`Etiqueta de fin (opcional)`}
                        className='w-full ml-2 border-0 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300'
                    />
                </div>
            </div>
        </div>
    );
}