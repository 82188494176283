import { Controller } from "@hotwired/stimulus";
import * as L from "leaflet";

export default class extends Controller {
  static values = {
    position: Array,
  };

  declare positionValue: [string, string];

  connect() {
    const [lat, lon] = this.positionValue.map((p: string) => parseFloat(p))

    const map = L.map('map', {
      center: [lat, lon],
      zoom: 15,
      zoomControl: false,
      dragging: false
    })

    L.marker([lat, lon]).addTo(map);
  }
}
