import type {QuestionType} from "@/stores/report-editor-store.js";

export function Text({ type }: {type: QuestionType}) {
    const textMap: Record<string, string> = {
        short: 'Texto de respuesta breve',
        paragraph: 'Texto de respuesta largo',
    };

    const text = textMap[type];

    return text ? (
        <div className='w-1/2 border-b pb-2'>
            <span className='text-sm'>{text}</span>
        </div>
    ) : null;
}