import { Controller } from "@hotwired/stimulus";
import debounce from "@/util/debounce.ts";

interface BaseClientData {
  address?: string;
  district?: string;
  province?: string;
  region?: string;
  type: string;
}

interface PersonData extends BaseClientData {
  first_name: string;
  last_name: string;
  document_number: string;
}

interface BusinessData extends BaseClientData {
  business_name: string;
  ruc: string;
  condition: string;
  active: string;
}

type DocumentType = "dni" | "ruc" | "ce";

export default class ClientNewForm extends Controller {
  static targets = [
    "documentType",
    "localField",
    "foreignerField",
    "businessField",
    "firstNameField",
    "lastNameField",
    "businessNameField",
    "addressField",
    "regionField",
    "provinceField",
    "districtField",
  ];

  static values = {
    clientsPath: String,
  };

  declare documentTypeTarget: HTMLSelectElement;

  declare localFieldTargets: HTMLDivElement[];
  declare foreignerFieldTargets: HTMLDivElement[];
  declare businessFieldTargets: HTMLDivElement[];

  declare documentType: DocumentType;

  declare firstNameFieldTarget: HTMLInputElement;
  declare lastNameFieldTarget: HTMLInputElement;

  declare businessNameFieldTarget: HTMLInputElement;

  declare addressFieldTarget: HTMLInputElement;

  declare regionFieldTarget: HTMLSelectElement;
  declare provinceFieldTarget: HTMLSelectElement;
  declare districtFieldTarget: HTMLSelectElement;

  declare clientsPathValue: string;

  declare debouncedSearchData: (documentName: string, type?: string) => void;

  documentMemo = "";

  missingFields: string[] = [];

  expectDocumentLength = {
    dni: 8,
    ruc: 11,
    ce: 9,
  };

  connect() {
    this.documentType = this.documentTypeTarget.value as DocumentType;

    this.debouncedSearchData = debounce(
      (documentNumber: string, type: "dni" | "ruc" | "ce") => {
        if (this.documentMemo === documentNumber) {
          document.dispatchEvent(
            new CustomEvent("autodeal:input-fetch-success")
          );
          return;
        }

        this.fetchClient(documentNumber)
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              throw new Error(JSON.stringify(response));
            }

            window.Turbo.visit(`${this.clientsPathValue}/${response.data.id}`);
          })
          .catch(async () => {
            await this.fetchDocument(documentNumber, type);
          });
      },
      1250
    );
  }

  public documentNumberChange(event: KeyboardEvent) {
    const target = event.currentTarget as HTMLInputElement;

    const document = this.formatDocumentInput(target.value);
    target.value = document;

    if (document.length === this.expectDocumentLength[this.documentType]) {
      this.debouncedSearchData(document, this.documentType);
    }
  }

  public documentTypeChange(event: KeyboardEvent) {
    const target = event.currentTarget as HTMLInputElement;

    const fields = this.localFieldTargets.concat(this.businessFieldTargets);

    this.documentType = target.value as DocumentType;
    this.clearFields();

    const handlePerson = () => {
      this.localFieldTargets.forEach((field) => {
        field.classList.add("animate-opacity-in");
        field.classList.remove("hidden");
      });
    };

    const handleBusiness = () => {
      this.businessFieldTargets.forEach((field) => {
        field.classList.remove("hidden");
      });
    };

    const documentMap = {
      dni: handlePerson,
      ruc: handleBusiness,
      ce: handlePerson,
    };

    fields.forEach((field) => {
      field.classList.add("hidden");
    });

    documentMap[this.documentType]();
  }

  private setValueToInput(input: HTMLInputElement, value: string) {
    const trimmedValue = value?.trim();
    if (trimmedValue) {
      input.value = trimmedValue;
    } else {
      this.missingFields.push(
        input.closest(".field").querySelector("label").textContent
      );
      input.removeAttribute("readonly");
      input.classList.replace("bg-gray-100", "bg-blue-50");
      input.classList.replace("ring-gray-300", "ring-blue-300");
      input.setAttribute("placeholder", "Ingresar valor manualmente");
    }
  }

  private selectLocationField(
    field: HTMLSelectElement,
    value: string,
    callback?: () => void
  ) {
    setTimeout(() => {
      const option = Array.from(field.querySelectorAll("option")).find(
        (option) => {
          return option.value.toLowerCase().includes(value);
        }
      );

      field.value = option?.value || value;

      field.dispatchEvent(new Event("change"));

      if (callback) callback();
    }, 500);
  }

  private notifyMissingFields() {
    document.dispatchEvent(
      new CustomEvent("autodeal:notifications", {
        detail: {
          title: "Advertencia",
          type: "info",
          message: `<div>
                                <p class="text-xs">Los siguientes campos no pudieron ser recuperados:</p>
                                <ul class="list-disc pl-5">
                                    ${this.missingFields
                                      .map((field) => `<li>${field}</li>`)
                                      .join("")}
                                </ul>
                              </div>`,
        },
      })
    );
    this.missingFields = [];
  }

  private setClientValues(client: PersonData | BusinessData) {
    document.dispatchEvent(new CustomEvent("autodeal:input-fetch-success"));

    if ("ruc" in client) {
      this.setValueToInput(this.businessNameFieldTarget, client.business_name);
    } else {
      this.setValueToInput(this.firstNameFieldTarget, client.first_name);
      this.setValueToInput(this.lastNameFieldTarget, client.last_name);
    }

    if (client.type === "foreigner") {
      this.addressFieldTarget.removeAttribute("readonly");
      this.addressFieldTarget.classList.remove("bg-gray-100");
      return;
    }
    this.setValueToInput(this.addressFieldTarget, client.address);

    if (this.missingFields.length > 0) {
      this.notifyMissingFields();
    }

    const { region, province, district } = client;

    this.selectLocationField(
      this.regionFieldTarget,
      region.toLowerCase(),
      () => {
        this.selectLocationField(
          this.provinceFieldTarget,
          province.toLowerCase(),
          () => {
            this.selectLocationField(
              this.districtFieldTarget,
              district.toLowerCase()
            );
          }
        );
      }
    );
  }

  private fetchClient(documentNumber: string) {
    this.documentMemo = documentNumber;

    return fetch(`/api/v1/clients/${documentNumber}`);
  }

  private async fetchDocument(documentNumber: string, type = "dni") {
    this.documentMemo = documentNumber;

    try {
      const path = {
        dni: "people",
        ruc: "business",
        ce: "foreigners",
      }[type];

      const response = await fetch(`/api/external/${path}/${documentNumber}`);

      if (!response.ok) {
        const message = await response.json();

        throw new Error(JSON.stringify(message));
      }

      const { data } = await response.json();

      this.setClientValues(data as PersonData | BusinessData);

      return data;
    } catch (error) {
      document.dispatchEvent(new CustomEvent("autodeal:input-fetch-fail"));
      console.log(error);
      this.clearFields();
    }
  }

  private formatDocumentInput(document: string) {
    let expectedLength = this.expectDocumentLength[this.documentType];

    document = document.replace(/\D/g, "");

    if (document.length > expectedLength) {
      document = document.slice(0, expectedLength);
    }

    return document;
  }

  private clearFields() {
    this.addressFieldTarget.setAttribute("readonly", "true");
    this.addressFieldTarget.classList.add("bg-gray-100");

    // Upper fields
    for (const input of document.querySelectorAll(
      '[data-role="unlocked"] input'
    )) {
      const field = input as HTMLInputElement;
      field.value = "";
      field.classList.remove("!bg-emerald-50", "!ring-emerald-300");
    }
    // Lower fields (fetched)
    for (const input of document.querySelectorAll(".field input")) {
      const field = input as HTMLInputElement;
      field.value = "";
      field.classList.replace("bg-blue-50", "bg-gray-100");
      field.classList.replace("ring-blue-300", "ring-gray-300");
      field.setAttribute("readonly", "true");
      field.removeAttribute("placeholder");
    }
  }
}
