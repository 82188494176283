import { signal } from "@preact/signals";

export type Notification = {
  id: string;
  type: string;
  title: string;
  message: string;
}

export const store = signal([] as Notification[]);

export function remove(id : string) {
  store.value = store.value.filter((notification) => notification.id !== id);
}

export function add(type : string, title : string, message : string) {
  const id = crypto.randomUUID();

  store.value = [...store.value, { id, type, title, message } as Notification]

  setTimeout(() => {
    remove(id);
  }, 8000);
}
