import { type ActionEvent, Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'add',
        'form',
    ]

    maxFiles = 10
    allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4']

    declare addTarget: HTMLInputElement
    declare formTarget: HTMLFormElement

    trigger() {
        this.addTarget.click()
    }

    upload(event: ActionEvent) {
        const target = event.target as HTMLInputElement
        const files = target.files
        const errors = []

        let isValid = true

        if (files === null) return;

        for (const file of files) {
            if (!this.allowedTypes.includes(file.type)) {
                isValid = false
                errors.push(`File ${file.name} is not allowed. Allowed types are ${this.allowedTypes.join(', ')}`)
            }
        }

        if (!isValid) {
            alert(errors.join('\n'))
            target.value = ''
            return
        }

        this.formTarget.dispatchEvent(new Event('submit', {bubbles: true}))
    }
}
