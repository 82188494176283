import { optionsController, type QuestionData, QuestionType } from "@/stores/report-editor-store.js";

interface CheckBoxProps {
    index: number;
    data: QuestionData;
    type: QuestionType;
}

export function Multiple({index, data, type}: CheckBoxProps) {
    const handleOptionAdd = () => optionsController.add(index, 'rows');
    const handleOptionChange = (optionIndex: number, value: string) => optionsController.edit(index, optionIndex, value, 'rows');
    const handleOptionDelete = (optionIndex: number) => optionsController.remove(index, optionIndex, 'rows');

    const iconMap = {
        [QuestionType.multipleChoice]: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                 strokeLinejoin="round" className="lucide lucide-square">
                <rect width="18" height="18" x="3" y="3" rx="2"/>
            </svg>),
        [QuestionType.checkbox]: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 className="lucide lucide-circle">
                <circle cx="12" cy="12" r="10"/>
            </svg>
        )
    }

    return (
        <div className='w-full pb-2'>
            <span className='text-sm'>
                {data.rows && (data.rows.map((row, i) => (
                    <div key={i} className='flex items-center'>
                        {type === QuestionType.dropdown ? (<span className='text-lg'>{i + 1}.</span>) : iconMap[type]}
                        <input
                            type='text'
                            className='w-full ml-2 border-0 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300'
                            placeholder={`Opción ${i + 1}`}
                            value={row ?? `Opción ${i + 1}`}
                            onChange={(event) => handleOptionChange(i, (event.target as HTMLInputElement).value)}
                        />
                        {i !== 0 && (
                            <button
                                onClick={() => handleOptionDelete(i)}
                                className='focus:bg-gray-100 scale-95 rounded-full p-1 transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="lucide lucide-x">
                                    <path d="M18 6 6 18"/>
                                    <path d="m6 6 12 12"/>
                                </svg>
                            </button>
                        )}
                    </div>
                )))}
                <div>
                    <button
                        onClick={handleOptionAdd}
                        className='text-sm text-gray-500 mt-2 py-2 pl-2'>
                        Agregar opción
                    </button>
                </div>
            </span>
        </div>
    );
}