import { useEffect, useState } from "preact/hooks";
import {
  store,
  type Labor as LaborType,
  type Spare as SpareType,
} from "@/stores/confirmation-cart-store.ts";
import { Labor } from "@/components/confirmations/show/Labor.tsx";
import { Spare } from "@/components/confirmations/show/Spare.tsx";

export function ItemList() {
  const [labors, setLabors] = useState<{ [key: string]: LaborType }>({});
  const [spares, setSpares] = useState<{ [key: string]: SpareType }>({});

  useEffect(() => {
    store.subscribe((state) => {
      setLabors(state.labors);
      setSpares(state.spares);
    });
  }, []);

  return (
    <ul>
      {Object.keys(labors).map((item, key) => (
        <Labor
          key={key}
          description={labors[item].description}
          price={labors[item].price}
        />
      ))}
      {Object.keys(spares).map((item, key) => (
        <Spare key={key} name={spares[item].name} price={spares[item].price} />
      ))}
      {Object.keys(labors).length !== 0 || Object.keys(spares).length !== 0 ? (
        <>
          <li>
            <hr className="my-1 border-gray-200" />
          </li>
          <li>
            <div className="flex my-0.5">
              <span className="flex-1 pr-10 text-sm truncate block overflow-hidden whitespace-nowrap">
                Total
              </span>
              <span className="text-sm"> </span>
            </div>
          </li>
        </>
      ) : null}
    </ul>
  );
}
