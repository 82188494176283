import FiltersController from "@/common/filters_controller.js";
import debounce from "@/util/debounce.js";

export default class extends FiltersController {
    declare debouncedNameSearch: (name: string) => void;
    declare debouncedDocumentSearch: (documentNumber: string) => void;

    connect() {
        super.connect();

        this.debouncedNameSearch = debounce(
            (name: string) =>
                this.setSearchParameterAndVisit(
                    "name",
                    name
                ),
            550
        );

        this.debouncedDocumentSearch = debounce(
            (documentNumber: string) =>
                this.setSearchParameterAndVisit(
                    "document_number",
                    documentNumber
                ),
            550
        );
    }

    //? Actions
    nameSearch(event: Event) {
        const target = event.target as HTMLInputElement;
        this.debouncedNameSearch(target.value);
    }

    documentSearch(event: Event) {
        const target = event.target as HTMLInputElement;
        this.debouncedDocumentSearch(target.value);
    }

    documentTypeSelect({ currentTarget }: Event) {
        this.setSearchParameterAndVisit(
            "document_type",
            (currentTarget as HTMLSelectElement).value
        );
    }

    //? End Actions


}